import { EMPTY_REVENUE_RECONCILIATION_REPORT_ENTITY, RevenueReconciliationReportEntity } from "domain/entity/RevenueReconciliationReport/RevenueReconciliationReportEntity";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface RevenueReconciliationReportDropdownOptions {
    printQueueDropdownOptions: DropdownProps[],
    currencyDropdownOptions: DropdownProps[],
}
export interface RevenueReconciliationReportViewChangeState extends BaseViewChangeSate {
}

export interface RevenueReconciliationReportModel {
    isLoading: boolean,
    reportIdList: string[],
    reportTypeList: string[],
    printQueueIdMap: { [key: string]: number },
    reportIdTypeMap: { [key: string]: string },
    dynamicOptions: RevenueReconciliationReportDropdownOptions,
    currentReport: RevenueReconciliationReportEntity,
    reportTypeCheckboxOptions: GroupCheckboxList[],

}

export const EMPTY_REVENUE_RECONCILIATION_REPORT_MODEL: RevenueReconciliationReportModel = {
    isLoading: false,
    dynamicOptions: {
        printQueueDropdownOptions: [],
        currencyDropdownOptions: []
    },
    printQueueIdMap: {},
    reportTypeList: [],
    currentReport: { ...EMPTY_REVENUE_RECONCILIATION_REPORT_ENTITY },
    reportIdTypeMap: {},
    reportIdList: [],
    reportTypeCheckboxOptions: []
}