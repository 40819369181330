import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { RevenueReconciliationReportRepoImpl } from "domain/repository/RevenueReconciliationReport/RevenueReconciliationReportRepoImpl";
import { useRevenueReconciliationReportTracked } from "presentation/store/RevenueReconciliationReport/RevenueReconciliationReportProvider";
import { RevenueReconciliationReportVM } from "presentation/viewModel/RevenueReconciliationReport/RevenueReconciliationReportVM";
import { useMemo } from "react";

export const useRevenueReconciliationReportVM = () => {
    const [, setRevenueReconciliationReportState] = useRevenueReconciliationReportTracked();
    const revenueReconciliationReportVM = useMemo(() =>
        RevenueReconciliationReportVM({
            dispatch: [setRevenueReconciliationReportState],
            revenueReconciliationReportRepo: RevenueReconciliationReportRepoImpl(),
            printQueueRepo: PrintQueueRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
        }), [setRevenueReconciliationReportState])

    return revenueReconciliationReportVM
}