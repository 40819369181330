export interface RevenueReconciliationReportEntity {

    reportTypeList: string[],
    printer: string,
    currency?: string | null,
    cutOffDateFrom?: Date | null,
    cutOffDateTo?: Date | null,

    [key: string]: string | boolean | Date | number | null | Object | undefined
}

export const EMPTY_REVENUE_RECONCILIATION_REPORT_ENTITY: RevenueReconciliationReportEntity = {
    reportTypeList: [],
    printer: "",
    currency: "",
    cutOffDateFrom: null,
    cutOffDateTo: null,
}
