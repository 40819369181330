import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { RevenueReconciliationReportEntity } from "domain/entity/RevenueReconciliationReport/RevenueReconciliationReportEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import reportAxiosInstance from "../axios/reportAxiosInstance";
import { RevenueReconciliationReportRepository } from "./RevenueReconciliationReportRepo";

export const RevenueReconciliationReportRepoImpl = (): RevenueReconciliationReportRepository => {


    const initRevenueReconciliationReportList = async (): Promise<string[]> => {
        const searchUrl = "/v1/initRevenueReconciliationReportList"
        return axiosGetData(reportAxiosInstance, `${searchUrl}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const print = async (entity: RevenueReconciliationReportEntity): Promise<ResponseEntity> => {
        const printUrl = "/v1/revenueReconciliationReportPrint"
        return await axiosPostData(reportAxiosInstance, `${printUrl}`, entity);
    }

    const view = async (entity: RevenueReconciliationReportEntity): Promise<ResponseEntity> => {
        const viewUrl = "/v1/revenueReconciliationReportView"
        return await axiosPostData(reportAxiosInstance, `${viewUrl}`, entity);
    }

    return {
        initRevenueReconciliationReportList: initRevenueReconciliationReportList,
        print: print,
        view: view,
    }
}
