
export const RevenueReconciliationReportConstant = {
    Table: {
        REPORT_TYPE: "Report Type",
        PRINTER: "Printer",
        CUT_OFF_DATE_FROM: "Cut Off Date (From)",
        CUT_0FF_DATE_TO: "Cut Off Date (To)",
        CURRRENCY: "Currency",
    },
    Title: {
        TITLE: "Revenue And Reconciliation",
    },
}
