import { RevenueReconciliationReportConstant } from "presentation/constant/RevenueReconciliationReport/RevenueReconciliationReportConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useRevenueReconciliationReportVM } from "presentation/hook/RevenueReconciliationReport/useRevenueReconciliationReportVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useRevenueReconciliationReportTracked } from "presentation/store/RevenueReconciliationReport/RevenueReconciliationReportProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, GroupCheckboxList, HPHButton, HPHGroupCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const RevenueReconciliationReportPanel = () => {
    const REVENUE_RECONCILIATION_REPORT_CONSTANT = RevenueReconciliationReportConstant.Table;
    const [revenueReconciliationReportState] = useRevenueReconciliationReportTracked();
    const revenueReconciliationReportVM = useRevenueReconciliationReportVM();
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();
    const { currentReport, reportIdTypeMap } = revenueReconciliationReportState;

    const handleReset = useCallback(() => {
        revenueReconciliationReportVM.onResetClick();
    }, [revenueReconciliationReportVM]);



    const handlePrint = useCallback(() => {
        if (currentReport.reportTypeList.length === 0) {
            messageBarVM.showError("Please select one Report at least.");
            return;
        }
        if (currentReport.cutOffDateFrom && currentReport.cutOffDateTo && currentReport.cutOffDateFrom > currentReport.cutOffDateTo) {
            messageBarVM.showError("Cut Off Date (To) should be later than or equals to Cut Off Date (From).");
            return;
        }
        setIsLoading(true);
        revenueReconciliationReportVM.onPrintClick(reportIdTypeMap, currentReport).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Report is sent to printers successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Report print failed.");
            }
        });
    }, [currentReport, messageBarVM, reportIdTypeMap, revenueReconciliationReportVM]);


    const handleView = useCallback(() => {
        if (currentReport.reportTypeList.length === 0) {
            messageBarVM.showError("Please select one Report at least.");
            return;
        }
        if (currentReport.cutOffDateFrom && currentReport.cutOffDateTo && currentReport.cutOffDateFrom > currentReport.cutOffDateTo) {
            messageBarVM.showError("Cut Off Date (To) should be later than or equals to Cut Off Date (From).");
            return;
        }
        setIsLoading(true);
        revenueReconciliationReportVM.onViewClick(reportIdTypeMap, currentReport).then(data => {
            setIsLoading(false);
        });
    }, [currentReport, messageBarVM, reportIdTypeMap, revenueReconciliationReportVM]);

    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.printer ?? ''}
                fieldValue={currentReport.printer}
                fieldLabel={REVENUE_RECONCILIATION_REPORT_CONSTANT.PRINTER}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printer'}
                maxLength={60}
                options={revenueReconciliationReportState.dynamicOptions.printQueueDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    revenueReconciliationReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REVENUE_RECONCILIATION_REPORT_CONSTANT.PRINTER, revenueReconciliationReportState.dynamicOptions.printQueueDropdownOptions, revenueReconciliationReportVM, currentReport.printer])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.currency ?? ''}
                fieldValue={currentReport?.currency}
                fieldLabel={REVENUE_RECONCILIATION_REPORT_CONSTANT.CURRRENCY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currency'}
                maxLength={60}
                options={revenueReconciliationReportState.dynamicOptions.currencyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    revenueReconciliationReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.currency, REVENUE_RECONCILIATION_REPORT_CONSTANT.CURRRENCY, revenueReconciliationReportState.dynamicOptions.currencyDropdownOptions, revenueReconciliationReportVM])

    const memoCutOffDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={REVENUE_RECONCILIATION_REPORT_CONSTANT.CUT_OFF_DATE_FROM}
                width="300px"
                date={currentReport?.cutOffDateFrom}
                fieldName="cutOffDateFrom"
                errorMessage={""}
                onDateChange={revenueReconciliationReportVM.onHeaderFieldChange} />
        </div>
        , [REVENUE_RECONCILIATION_REPORT_CONSTANT.CUT_OFF_DATE_FROM, currentReport?.cutOffDateFrom, revenueReconciliationReportVM.onHeaderFieldChange])

    const memoCutOffDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={REVENUE_RECONCILIATION_REPORT_CONSTANT.CUT_0FF_DATE_TO}
                width="300px"
                date={currentReport?.cutOffDateTo}
                fieldName="cutOffDateTo"
                errorMessage={""}
                onDateChange={revenueReconciliationReportVM.onHeaderFieldChange} />
        </div>
        , [REVENUE_RECONCILIATION_REPORT_CONSTANT.CUT_0FF_DATE_TO, currentReport?.cutOffDateTo, revenueReconciliationReportVM.onHeaderFieldChange])

    const memoReportCheckBox = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px" }}>
            <HPHGroupCheckbox
                label={REVENUE_RECONCILIATION_REPORT_CONSTANT.REPORT_TYPE}
                checkboxData={revenueReconciliationReportState.reportTypeCheckboxOptions}
                selectedValues={currentReport.reportTypeList}
                orientation={"vertical"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => revenueReconciliationReportVM.onGroupCheckboxChange(e)}
            />
        </div>
        , [REVENUE_RECONCILIATION_REPORT_CONSTANT.REPORT_TYPE, currentReport.reportTypeList, revenueReconciliationReportState.reportTypeCheckboxOptions, revenueReconciliationReportVM])

    return <>

        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{RevenueReconciliationReportConstant.Title.TITLE}</HeaderTitle>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            <StyledAction className="tm-animated-wrapper">
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CLEAR} size={"Small"} theme={"Secondary"} disabled={false} onClick={handleReset} />
                <div className="add-seperator" />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_VIEW} size={"Small"} theme={"Secondary"} disabled={false} onClick={handleView} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={"Small"} theme={"Primary"} disabled={false} onClick={handlePrint} />
            </StyledAction>
        </Sidebarheader>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoPrinter}
                            {memoCurrency}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCutOffDateFrom}
                            {memoCutOffDateTo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoReportCheckBox}
                        </CriteriaItemContainer>


                    </div>
                </div>
            </div>
        </StyledSidebar >
    </>
}

export default memo(RevenueReconciliationReportPanel);
